<template>
    <div>
        <MainChat />
        <ChatSummary />
    </div>
</template>

<script>
import MainChat from "./Components/MainChat";
import ChatSummary from "./Components/ChatSummary";


export default {
    components:{MainChat, ChatSummary},
  data() {
    return {
    };
  },
};
</script>

<style>
body {
  margin: 0;
}

.text {
  background-color: rgba(21, 21, 21, 0.1);
  border-radius: 8px;
  border-width: 0;
  padding: 10px 12px;
  text-align: center;
  white-space: nowrap;
  margin: 0 0 15px;
}
.button {
  width: 145px;
  background-color: #cb1f26;
  border-radius: 25px;
  border-width: 0;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 5px;
  list-style: none;
  margin: 0 5px 16px 5px;
  padding: 20px 24px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button:disabled {
  background-color: rgba(251, 51, 51, 0.05);
  cursor: default;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 20px #ff4f4f;
  }
  50% {
    box-shadow: 0 0 30px #efb4b4;
  }
  100% {
    box-shadow: 0 0 20px #ff4f4f;
  }
}
.button_anim {
  animation: pulse 2500ms infinite;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #fafafa;
  min-height: 100vh;
}
</style>
