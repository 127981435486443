<template>
    <div style="padding-top: 35px; padding-bottom: 25px">
        <h1 class="main-title">
          Zavarovanje doma
        </h1>

        <h2 class="subtitle">
          Call center
        </h2>

        <div>
          <button
              class="button"
              :class="{button_anim: store.recording && store.state === 'Govori v mikrofon!'}"
              :disabled="store.disabledButton"
              @click="recWithMicrosoftSDK()"
          ><!--Recording -->
            {{ store.button }}
          </button>

          <button
              class="button"
              style="margin-left: 5px;
              background-color: grey"
              v-if="store.state === 'Govori v mikrofon!'"
              @click="pauseRecording"
          >
            {{ pause.title }}
          </button>
        </div>

        <div v-if="(store.state === 'Govori v mikrofon!' && !pause.isPaused) || store.state === 'Inicializiram pogovor'" style="margin-top: 12px; margin-bottom: 12px">
            <img
                v-if="store.state === 'Inicializiram pogovor'"
                style="width: 60px"
                src="@/assets/loading.svg"
            >

            <div>
              {{ store.state }}
            </div>
        </div>

        <div class="text-section" :hidden="store.text === '' && store.state === '' && !store.disabledButton">
            <template v-for="(text, index) in store.text">
                <div v-if="isQuestion(text)" :key="`question_${index}`" class="question">
                    {{ text.replace("Q: ", "") }}
                </div>

                <div v-else :key="`answer_${index}`" class="answer">
                    <h4 style="margin: 0 0 5px 0; font-family: Avenir, Helvetica, Arial, sans-serif">AI odgovarja:</h4>
                    {{ text.replace("A: ", "") }}

                    <img style="position: absolute; top: 10px; right: 10px; width: 15px; cursor: pointer" src="@/assets/play-button.svg"  @click="replay(text)">
                </div>
            </template>

            <div v-if="store.state === 'AI Razmišlja'">
              <img
                  style="width: 50px"
                  src="@/assets/loading.svg"
              >

              <div>{{ store.state }}</div>
            </div>
        </div>
    </div>

</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'
import {Connection, ResultReason} from 'microsoft-cognitiveservices-speech-sdk';
import {store} from '@/store/store.js'

const speechsdk = require('microsoft-cognitiveservices-speech-sdk')

export default {
    name: 'MainChat',
    data() {
        return {
            store,
            pause: {
              title: 'Utišaj',
              isPaused: false
            }
        };
    },
    methods: {
        pauseRecording() {
            if (this.pause.isPaused) {
              this.continueRecording();
              return;
            }

            this.pause.title = 'Nadaljuj';
            this.pause.isPaused = true;
            this.store.recording = false;
            this.store.recognizer.stopContinuousRecognitionAsync();
        },
        continueRecording() {
            this.pause.title = 'Utišaj';
            this.pause.isPaused = false;
            this.store.recording = true;
            this.store.recognizer.startContinuousRecognitionAsync();
        },
        async stopRecording() {
            // Leave state if still waiting for answer. State will be set to default after answer is provided
            if (this.store.state !== 'AI Razmišlja') {
              this.store.state = ""
              this.store.askingAssistant = false
            }

            this.pause.title = 'Utišaj';
            this.pause.isPaused = false;
            this.store.recording = false
            this.store.button = "Začni klic"
            await this.store.recognizer.stopContinuousRecognitionAsync()

        },
        async recWithMicrosoftSDK() {
            if (this.store.recording || this.pause.isPaused) {
                await this.stopRecording();
            } else {
                this.store.disabledButton = true
                this.store.recording = true
                this.store.button = "Ustavi klic"
                this.store.text = []
                this.store.state = 'Inicializiram pogovor'
                const tokenObj = await this.getTokenOrRefresh();
                const speechConfig = await speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
                const synthConfig = await speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region)
                speechConfig.speechRecognitionLanguage = 'sl-SI'//'en-US'
                synthConfig.speechSynthesisLanguage = 'sl-SI'

                const audioConfig = await speechsdk.AudioConfig.fromDefaultMicrophoneInput()
                console.log("audioConfig", audioConfig)
                //const file = new File(['/public/'], 'download.wav', { type: 'audio/wav' })
                //const audioConfig = speechsdk.AudioConfig.fromWavFileInput(file)
                this.store.recognizer = await new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
                // const synthesizer = await new speechsdk.SpeechSynthesizer(synthConfig)
                const conn = Connection.fromRecognizer(this.store.recognizer);
                conn.setMessageProperty("speech.context", "phraseDetection", {
                    "INTERACTIVE": {
                        "segmentation": {
                            "mode": "custom",
                            "segmentationSilenceTimeoutMs": 2500
                        }
                    },
                    mode: "Interactive"
                });
                navigator.permissions.query({name: 'microphone'})
                    .then((permissionObj) => {
                        console.log("PERMISSION")
                        console.log(permissionObj);
                    })
                    .catch((error) => {
                        console.log('Got error :', error);
                    })
                let resultText = "";
                this.store.summary = "";
                this.store.recognizer.recognizing = async (s, e) => {
                    console.log(s, e)
                    this.store.waitingForResponse = true;
                }
                this.store.recognizer.recognized = async (s, e) => {
                    console.log(e)
                    if (e.result.reason === ResultReason.RecognizedSpeech) {

                        //Display continuous transcript
                        resultText = `Q: ${e.result.text}`;
                        this.store.text.push(resultText);
                        const answer = await this.askAssistant(e.result.text);
                        // const answer = "ratatatata."
                        resultText = `A: ${answer}`;
                        this.store.text.push(resultText);
                        const synthesizer = await new speechsdk.SpeechSynthesizer(synthConfig)

                        synthesizer.speakTextAsync(answer, () => {
                          synthesizer.close();
                        });
                        // this.stopRecording()
                        this.store.waitingForResponse = false;

                    } else if (e.result.reason === ResultReason.NoMatch) {
                        //resultText += `\nNo Match`
                        resultText += ''//`\n`
                    }
                };
                await this.store.recognizer.startContinuousRecognitionAsync();
                this.store.recognizer.sessionStarted = () => {
                    this.store.state = 'Govori v mikrofon!'
                    this.store.disabledButton = false;
                }
            }
        },
        async getTokenOrRefresh() {
            const speechToken = Cookies.get('speech-token');
            if (speechToken === undefined || speechToken === 'undefined:undefined') {
                try {
                    console.log('Try getting token from the express backend');
                    const res = await axios.get('https://stag.mmv.si/api/get-token'); //TOKEN
                    console.log(res)
                    const token = res.data.token;
                    const region = res.data.region
                    Cookies.set('speech-token', region + ":" + token, {expires: new Date(new Date().getTime() + 1 * 60000)}) //1 minut
                    console.log('Token fetched from back-end: ' + token);
                    return {authToken: token, region: region};
                } catch (err) {
                    console.log(err.response.data);
                    return {authToken: null, error: err.response.data};
                }
            } else {
                console.log('Token fetched from cookie: ' + speechToken);
                const idx = speechToken.indexOf(':');
                return {authToken: speechToken.slice(idx + 1), region: speechToken.slice(0, idx)};
            }
        },
        async getKeyPhrases(requestText) {
            try {
                //Key Phrase extraction
                const data = {transcript: requestText};
                const headers = {'Content-Type': 'application/json'};
                const res = await axios.post('/azure/language/ta-key-phrases', data, {headers});
                return res.data;
            } catch (err) {
                return {keyPhrasesExtracted: "NoKP", entityExtracted: "NoEnt"};
            }
        },
        async askAssistant(text) {
            this.store.askingAssistant = true;
            this.store.state = 'AI Razmišlja'
            const url = 'https://stag.mmv.si/api/v1/prediction/ea829d20-ab08-4107-b406-05588a2ad83b'
            try {
                console.log('Asking assistant');
                const data = {
                    question: text
                }
                const res = await axios.post(url, data); //TOKEN
                console.log(res)
                this.store.askingAssistant = false;

                if (!this.store.recording && !this.pause.isPaused) {
                  this.store.state = ''
                } else {
                  this.store.state = 'Govori v mikrofon!';
                }

                return res.data;
            } catch (err) {
                // console.log(err.response.data);
                this.store.askingAssistant = false;
                this.store.state = ''

                let error = err;

                if (err.response && err.response.data) {
                  error = err.response.data;
                }

                return {authToken: null, error: error };
            }
        },
        async replay(text){
            const tokenObj = await this.getTokenOrRefresh();
            const synthConfig = await speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region)
            synthConfig.speechSynthesisLanguage = 'sl-SI';
            const synthesizer = await new speechsdk.SpeechSynthesizer(synthConfig)
            synthesizer.speakTextAsync(text.replace("A: ", ""), () => {
              synthesizer.close();
            });

        },
        isQuestion(text) {
          return text.startsWith("Q: ");
        }
    }
};
</script>

<style>
.main-title {
  font-size: 30px;
  margin: 0;
}

.subtitle {
  font-size: 25px;
  margin-top: 5px;
  color: grey;
}

.text-section {
  margin: 0 30px;
}

.question {
  font-size: 15px;
  white-space: pre-line;
  margin-bottom: 10px;
  background-color: #ffcfd1;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-family: monospace;
}

.answer {
  font-size: 15px;
  white-space: pre-line;
  margin-bottom: 10px;
  background-color: #e3e3e3;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-family: monospace;
  position: relative;
}
</style>
