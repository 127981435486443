<template>
    <div class="text-section">
        <button class="button" style="background-color: #4c4c4c" v-if="(!store.waitingForResponse && store.text.length > 0 && store.state === '')"
                :disabled="store.waitingForSummaryResponse || store.text ==='' || store.summary !== '' || store.recording || store.waitingForResponse" @click="getSummary"><!--Recording -->
            Povzetek
        </button>
        <div class="summary" :hidden="store.summary === '' && !store.waitingForSummaryResponse">
            <div v-if="store.waitingForSummaryResponse" style="text-align: center">
                <img
                    style="width: 70px"
                    src="@/assets/loading.svg"
                >
                <p style="margin-bottom: 0">Osnutek pogovora</p>
            </div>

            <span style="font-family: monospace; white-space: pre-line">{{ store.summary }}</span>
        </div>
    </div>
</template>

<script>
import {store} from "@/store/store";
import axios from "axios";

export default {
    name: "ChatSummary",
    data() {
        return {
            store
        };
    },
    methods: {
        async getSummary(){
            this.store.waitingForSummaryResponse = true;
            this.store.summary = await this.summarizeText(await this.turncateText(this.store.text))
            this.store.waitingForSummaryResponse = false;
        },
        async summarizeText(requestText) {
            const summaryPrompt = requestText + "\n\nSummarize the conversation containing the names of the the agent and the customer and all the important topics discussed. In one line reply what the customer wanted and in one line reply what the answer was. Avoid sentence repetition. And reply in Slovenian language. ";
            console.log(summaryPrompt)
            const url = 'https://stag.mmv.si/api/v1/prediction/137c3e47-d2ae-467f-b398-5c3345f9be6a'
            try {
                console.log('Asking assistant');
                const data = {
                    question: summaryPrompt
                }
                const res = await axios.post(url, data); //TOKEN
                console.log(res)
                return res.data;
            } catch (err) {
                console.log(err.response.data);
                return {authToken: null, error: err.response.data};
            }
        },
        async turncateText(text) {
            const maxLength = 2700
            if (text.length <= maxLength) {
                return text;
            }

            const truncatedText = text.slice(0, maxLength);
            const lastSentenceEnd = truncatedText.lastIndexOf(".");

            if (lastSentenceEnd !== -1) {
                return truncatedText.slice(0, lastSentenceEnd + 1);
            }

            return truncatedText;
        }
    }
}
</script>

<style scoped>
.summary {
  font-size: 15px;
  white-space: pre-line;
  background-color: #e3e3e3;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-family: monospace;
  position: relative;
}

.text-section {
  margin: 0 30px;
  padding-bottom: 25px;
}
</style>
