// store.js
import { reactive } from 'vue'

export const store = reactive({
    button: "Začni klic",
    state: "",
    text: "",
    summary: "",
    recording: false,
    waitingForResponse: false,
    waitingForSummaryResponse: false,
    disabledButton: false,
    recognizer: null
})
